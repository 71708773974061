import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {environment} from '../../../../../environments/environment'
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  page: number = 1;
  count: number = 0;
  tableSize: number = 9;
  tableSizes: any = [3, 6, 9, 12];
  projectslist: any;
  showDeletedProject = false;
  projectToDelete: any;
  choosedProject:any;
  AllActive=true;
  AllPublished=false;
  AllHidden=false;
  AllDeleted=false;

  DeleteAction=false;
  HideAction=false;
  DisplayAction=false;
  RecoverAction = false;
  BaseUrl = environment.baseUrl.split('/api')[0] + '/media/actualite/';
  sizeListProjectslist :any //

  constructor(private projectService: ProjectService, private toastr: ToastrService, private router: Router,public iziToast: Ng2IzitoastService,) {
  }

  get_projects_list() {
    this.projectService.get_actualités_list().subscribe(data => {
      this.projectslist = data;
      this.sizeListProjectslist = this.projectslist.length
    });
  }
  get_valid_projects_list() {
    this.projectService.get_actualités_valid_list().subscribe(data => {
      this.projectslist = data;
      this.sizeListProjectslist = this.projectslist.length
    });
  }
  get_masqued_projects_list() {
    this.projectService.get_actualités_hidden_list().subscribe(data => {
      this.projectslist = data;
      this.sizeListProjectslist = this.projectslist.length
    });
  }
  get_deleted_projects_list() {
    this.projectService.get_actualités_deleted_list().subscribe(data => {
      this.projectslist = data;
    });
  }

  ngOnInit(): void {
    this.get_projects_list();
  }

  updateDisplayProject(project) {
    this.projectService.updateDisplayProject(project).subscribe(data => {
      this.toastr.success('L\'article a été modifiée avec succès!');
      this.get_projects_list();
    });
  }


  // deleteDisplayProject() {
  //   this.projectToDelete.deleted_page = !this.projectToDelete.deleted_page;
  //   this.projectService.delete_project(this.projectToDelete._id).subscribe(data => {
  //     this.toastr.success('L\'actualité a été supprimer avec succès!');
  //     this.get_projects_list();
  //   });
  // }

  displayingDeletedProjects() {
    this.showDeletedProject = !this.showDeletedProject;
  }
  hideActialite(project){
    project = this.choosedProject
    this.projectService.updateDisplayProject(project).subscribe(data =>{
      if(project.hide_page == false){
        //this.toastr.success('L\'article a été masqué avec succès!');
        this.iziToast.show({
          message:'L\'actualité a été masquée avec succès!',
          messageColor:'#343A40',
          progressBarColor:'#212529',
          icon:'bi-eye-slash',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#CED4DA',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',


           });

        this.HideAction =false;
        this.DisplayAction =false;
        this.GetAll()
      }else{
        //this.toastr.success('L\'article est affiché avec succès!');
        this.iziToast.show({
          message:'L\'actualité est affichée avec succès!',
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
        this.HideAction =false;
        this.DisplayAction =false;
        this.GetAll()

      }
      this.get_projects_list();
    })
  }

  deleteActialite(project){
    this.DeleteAction = true;
    project = this.choosedProject
    this.projectService.delete_project(project).subscribe((data) =>{
      if(project.deleted_page == false){
        this.DeleteAction = false;
        this.RecoverAction = false;

      //this.toastr.success('L\'article a été supprimé avec succès!');
      this.iziToast.show({
        message:'L\'actualité est supprimée avec succès!',
        messageColor:'#386641',
        progressBarColor:'#6a994e',
        icon:'bi-check-circle',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
         });
      
      this.GetAll();
      }else{
        this.DeleteAction = false;
        this.RecoverAction = false;

        this.toastr.success('L\'article est récupérée avec succès!');
        this.GetAll();
      }
      this.get_projects_list();
    },(err)=>{
      this.toastr.error('Une  erreur est  servenue  lors de la  suppression de l\'article!')
    })
  }
  setObjectTotrait(project){
    this.choosedProject = project
  }
  UpdatePage(project_id){
    this.router.navigate(['admin/actualites/modification/'+project_id]);
  }
  GetAll(){
    this.AllActive = true;
    this.AllDeleted = false;
    this.AllHidden=false;
    this.AllPublished =false;
this.get_projects_list();
  }
  GetPublished(){
    this.AllActive = false;
    this.AllDeleted = false;
    this.AllHidden=false;
    this.AllPublished =true;
    this.get_valid_projects_list()
  }
  GetHidden(){
    this.AllActive = false;
    this.AllDeleted = false;
    this.AllHidden=true;
    this.AllPublished =false;
    this.get_masqued_projects_list()
  }
  GetDeleted(){
    this.AllActive = false;
    this.AllDeleted = true;
    this.AllHidden=false;
    this.AllPublished =false;
    this.get_deleted_projects_list()
  }
  setDeleteMessage(project){
    if(project.deleted_page){
      this.DeleteAction =false;
      this.RecoverAction =true;

    }else{
      this.DeleteAction =true;
      this.RecoverAction =false;
    }
  }
  setHideMessage(project){
    if(project.hide_page){
      this.HideAction =false;
      this.DisplayAction =true;

    }else{
      this.HideAction =true;
      this.DisplayAction =false;
    }
  }

  Annuler(){
    this.DeleteAction = false;
    this.HideAction = false;
    this.DisplayAction = false;
    this.RecoverAction = false;
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.projectslist
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.projectslist
  }

  

}
