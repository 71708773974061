<br><br><br><br><br><br>
<div class="container" id="container">
 
  <div class="form-container sign-in-container">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <h1 style="color: #868579 !important;">Connexion</h1>
      <div class="social-container">
        <a href="/" class="social"><i class="bi bi-circle-fill" style="color: #CAEBFF;opacity: 50% !important;"></i></a>
        <a href="/" class="social"><i class="bi bi-circle-fill" style="color: #CAEBFF;opacity: 70% !important;"></i></a>
        <a href="/" class="social"><i class="bi bi-circle-fill" style="color: #CAEBFF;opacity: 100% !important;"></i></a>
      </div>
     
      <input placeholder="Email"
      type="email"
      formControlName="email"
     
      class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
    />
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">Veuillez renseigner ce champ.</div>
      <!-- <div *ngIf="f.email.errors.email">
        Merci de saisir une adresse email valide.
      </div> -->
      <div *ngIf="f.email.errors.pattern">
        Merci de saisir une adresse email valide.
      </div>
    </div>

    <input placeholder="Mot de passe"
    type="password"
    formControlName="password"
    class="form-control"
    [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
  />
  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
    <div *ngIf="f.password.errors.required">Veuillez renseigner ce champ.</div>
    
  </div>
    
      <br>
      <button style="color: #868579;" >Connexion</button>
      <br>
     
    </form>
  </div>
  <div class="overlay-container">
    <div class="overlay">
    
      <div class="overlay-panel overlay-right">
        <img class="img-fluid" src="assets/logo.png" alt="" width="250px" height="200px">
        <br>
        <p style="color: #888;">Bienvenue dans notre platforme Kemaqua</p>
      </div>
    </div>
  </div>
</div>
<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css'>    

