
    <!-- Header -->
    <header class="bg-surface-primary border-bottom pt-6">
        <div class="container-fluid">
            <div class="mb-npx">
                <div class="row align-items-center">
                    <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                        <!-- Title -->
                        <h1 class="h2 mb-0 ls-tight" style="color:#868579">Tableau de bord</h1>
                    </div>
                </div>
                <!-- Nav -->
                <ul class="nav nav-tabs mt-4 overflow-x border-0">
                    <li class="nav-item ">
                        <a  class="nav-link active"></a>
                    </li>
                    <li class="nav-item">
                        <a  class="nav-link font-regular"></a>
                    </li>
                    <li class="nav-item">
                        <a  class="nav-link font-regular"></a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
    <!-- Main -->
    <main class="py-6 bg-surface-secondary">
        <div class="container-fluid">
            <!-- Card stats -->
            <div class="row g-6 mb-6">
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Actualités</span>
                                    <span class="h3 font-bold mb-0">{{numberTotalNews}}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                        <i class="bi bi-card-list"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                                <!-- <span class="badge badge-pill bg-soft-success text-success me-2">
                                    <i class="bi bi-arrow-up me-1"></i>13%
                                </span> -->
                                <span class="text-nowrap text-xs text-muted">Nombre total des actualités</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Actualités publiées</span>
                                    <span class="h3 font-bold mb-0">{{numberPublishedNews}}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-rea text-white text-lg rounded-circle">
                                        <i class="bi bi-card-list"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                                <!-- <span class="badge badge-pill bg-soft-success text-success me-2">
                                    <i class="bi bi-arrow-up me-1"></i>13%
                                </span> -->
                                <span class="text-nowrap text-xs text-muted">Nombre total des actualités publiées</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Actualités masquées</span>
                                    <span class="h3 font-bold mb-0">{{numberMaskedNews}}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-jobs text-white text-lg rounded-circle">
                                        <i class="bi bi-card-list"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                                <!-- <span class="badge badge-pill bg-soft-success text-success me-2">
                                    <i class="bi bi-arrow-up me-1"></i>30%
                                </span> -->
                                <span class="text-nowrap text-xs text-muted">Nombre total des actualités masquées</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Offres d'emlpois</span>
                                    <span class="h3 font-bold mb-0">{{numberTotalJobs}}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                        <i class="bi bi-diagram-3"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                                <!-- <span class="badge badge-pill bg-soft-success text-success me-2">
                                    <i class="bi bi-arrow-up me-1"></i>13%
                                </span> -->
                                <span class="text-nowrap text-xs text-muted">Nombre total des offres d'emlpois</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Offres d'emlpois publiées</span>
                                    <span class="h3 font-bold mb-0">{{numberPublishedJobs}}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-rea text-white text-lg rounded-circle">
                                        <i class="bi bi-diagram-3"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                                <!-- <span class="badge badge-pill bg-soft-success text-success me-2">
                                    <i class="bi bi-arrow-up me-1"></i>13%
                                </span> -->
                                <span class="text-nowrap text-xs text-muted">Nombre total des offres d'emlpois publiées</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Offres d'emlpois masquées</span>
                                    <span class="h3 font-bold mb-0">{{numberMaskedJobs}}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-jobs text-white text-lg rounded-circle">
                                        <i class="bi bi-diagram-3"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                                <!-- <span class="badge badge-pill bg-soft-success text-success me-2">
                                    <i class="bi bi-arrow-up me-1"></i>30%
                                </span> -->
                                <span class="text-nowrap text-xs text-muted">Nombre total des offres d'emlpois masquées</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           </div>
           </main>