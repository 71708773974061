<!-- Banner -->
<!-- <a  class="btn w-full btn-primary text-truncate rounded-0 py-2 border-0 position-relative" style="z-index: 1000;">
  <strong style="color: #fff !important;">FINEO E-learning  </strong> 
</a> -->
<!-- Dashboard -->
<div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
  <!-- Vertical Navbar -->
  <nav class="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light border-bottom border-bottom-lg-0 border-end-lg" id="navbarVertical" style="background-color: #ffffff">
      <div class="container-fluid">
          <!-- Toggler -->
          <button class="navbar-toggler ms-n2" (click)="changenavbarclass()" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>
          <!-- Brand -->
          <a class="navbar-brand py-lg-5 mb-lg-4 px-lg-12 me-1" routerLink="/admin">
              <img src="assets/logo.png" alt="...">
          </a>
          <div class="navbar-user d-lg-none">
              <!-- Dropdown -->
              <div class="dropdown">
                  <!-- Toggle -->
                  <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div class="avatar-parent-child">
                          <span class="avatar-child avatar-badge bg-success"></span>
                      </div>
                  </a>
                  <!-- Menu -->
              </div>
          </div>
          <!-- Collapse -->
          <div [ngClass]="navbarclass"   id="sidebarCollapse">
              <!-- Navigation -->
              <ul class="navbar-nav">
                  <li class="nav-item">
                      <a class="nav-link" routerLink="/admin">
                          <i class="bi bi-house"></i> Tableau de bord
                      </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" routerLink="actualites">
                        <i class="bi bi-card-list"></i>
                        Actualités
                      </a>
                  </li>

                  <li class="nav-item">
                      <a class="nav-link" >
                        <i class="bi bi-hurricane"></i> Réalisations
                      </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" routerLink="emplois" >
                        <i class="bi bi-diagram-3"></i>
                        Emplois
                      </a>
                  </li>
                 
                 
              </ul>
              <!-- Divider -->
              <hr class="navbar-divider my-5 opacity-20">
              <hr class="navbar-divider my-5 opacity-20">
              <hr class="navbar-divider my-5 opacity-20">
              <ul class="navbar-nav">
                  <li class="nav-item">
                      <a style="cursor: pointer;" class="nav-link" (click)="logout()">
                          <i class="bi bi-box-arrow-left"></i> Déconnexion
                      </a>
                  </li>
              </ul>
          </div>
      </div>
  </nav>
  <!-- Main content -->
  
  <div class="h-screen flex-grow-1 overflow-y-lg-auto">
    <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-clip-rotate-pulse"
  >
    <p style="font-size: 20px; color: white">Veuillez patienter ...</p>
  </ngx-spinner>
    <router-outlet></router-outlet>
 </div>
 
</div>

