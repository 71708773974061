import { Component, OnInit } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { JobService } from 'src/app/_services/job.service';

@Component({
  selector: 'app-list-jobs',
  templateUrl: './list-jobs.component.html',
  styleUrls: ['./list-jobs.component.css']
})
export class ListJobsComponent implements OnInit {
jobs :any
jobToDeleted
jobToMaskedOrDisplay
statusJob
  constructor(private jobService :JobService,
    public iziToast: Ng2IzitoastService
     ) { }

  ngOnInit(): void {
    this.getJobs()
  }

  getJobs()
  {
     let count = 150;
     this.jobService.getJobs().subscribe(data =>{
       this.jobs=data
       for(let job of this.jobs){
            if(job.description.length >= 150){
            job.description = job.description.slice(0, count) + (job.description.length > count ? "..." : "");}
       }
  })
  }

  getJobToDelted(job)
  {
    this.jobToDeleted =job
  }

  getJobToMaskedOrDisplay(job)
  {
    this.jobToMaskedOrDisplay =job
    this.statusJob =this.jobToMaskedOrDisplay.publish
  }
  deleteJob() {
    this.jobService.deleteJob(this.jobToDeleted._id).subscribe((e) => {
      this.iziToast.show({
        message: "L'offre d'emploi est supprimée avec succès",
        messageColor: "#386641",
        progressBarColor: "#6a994e",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#dde5b6",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      });
      this.getJobs();
    }); 
  }


  maskedOrDisplayJob()
  {
    this.jobService.maskedOrDisplayJob(this.jobToMaskedOrDisplay._id).subscribe((e) => {
      if(this.statusJob == true){
      this.iziToast.show({
          message: "L'offre d'emploi a été masquée avec succées",
          messageColor:'#343A40',
          progressBarColor:'#212529',
          icon:'bi-eye-slash',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#CED4DA',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
      });
      }
      else{
      this.iziToast.show({
        message: "L'offre d'emploi est affichée  avec succès",
        messageColor: "#386641",
        progressBarColor: "#6a994e",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#dde5b6",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      });
      }
      this.getJobs();
    }); 
  }

}



/*
// filter
console.log(this.jobs.filter(
        (element) => element.publish === true)
    );
*/