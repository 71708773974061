<header class="bg-surface-primary border-bottom pt-6">
    <div class="container-fluid">
        <div class="mb-npx">
            <div class="row align-items-center">
                <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                    <!-- Title -->
                    <h1 class="h2 mb-0 ls-tight" style="color:#868579">Emplois</h1>
                </div>
            </div>
            <!-- Nav -->
            <ul class="nav nav-tabs mt-4 overflow-x border-0">
                <li class="nav-item ">
                    <a  class="nav-link active"></a>
                </li>
                <li class="nav-item">
                    <a  class="nav-link font-regular"></a>
                </li>
                <li class="nav-item">
                    <a  class="nav-link font-regular"></a>
                </li>
            </ul>
        </div>
    </div>
  </header>
  <!-- Main -->
  <main class="py-6 bg-surface-secondary">
    <div class="container-fluid">
      <div class="card shadow border-0 mb-7">
        <div class="card-header">
          <h5 *ngIf="fr" class="mb-0">Ajouter un nouveau emploi</h5>
          <h5 *ngIf="en" class="mb-0">Add new job</h5>
        </div>
  
        <div class="card m-3">
          <div class="card-body">
            <button class="btn btn-langue" (click)="setLangueFrancais()" [ngClass]="{'current0' : fr}" >FR</button>
            <button class="btn btn-langue" (click)="setLangueAnglais()"  [ngClass]="{'current0' : en}" style="margin-left: 1%;" >EN</button>
             <br>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" style="margin-top: 1%;">
                <div *ngIf="fr" >
              <div class="form-group">
                <label>Titre</label>
                <input
                  type="text"
                  formControlName="title"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                />
                <div
                  *ngIf="submitted && f.title.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.title.errors.required">
                    Champ obligatoire.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Sous_Titre</label>
                <input
                  type="text"
                  formControlName="subTitle"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.subTitle.errors }"
                />
                <div
                  *ngIf="submitted && f.subTitle.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.subTitle.errors.required">Champ obligatoire.</div>
                </div>
              </div>

              <div class="form-group">
                <label>Description</label>
                <angular-editor formControlName="description" [config]="editorConfig"  [ngClass]="{ 'is-invalid': submitted && f.description.errors }" ></angular-editor>

                <div
                  *ngIf="submitted && f.description.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.description.errors.required">Champ obligatoire.</div>
                </div>
              </div>

              <div class="form-group">
                <label>Lieu</label>
                <input
                  type="text"
                  formControlName="place"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.place.errors }"
                />
                <div
                  *ngIf="submitted && f.place.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.place.errors.required">Champ obligatoire.</div>
                </div>
              </div>
                </div>
         
                <div *ngIf="en" >
                    <div class="form-group">
                      <label>Title</label>
                      <input
                        type="text"
                        formControlName="titleEn"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.titleEn.errors }"
                      />
                      <div
                        *ngIf="submitted && f.titleEn.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.titleEn.errors.required">
                          Champ obligatoire.
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Sub_Title</label>
                      <input
                        type="text"
                        formControlName="subTitleEn"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.subTitleEn.errors }"
                      />
                      <div
                        *ngIf="submitted && f.subTitleEn.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.subTitleEn.errors.required">Champ obligatoire.</div>
                      </div>
                    </div>
      
                    <div class="form-group">
                      <label>Description</label>
                     
                      <angular-editor formControlName="descriptionEn" [config]="editorConfig"  [ngClass]="{ 'is-invalid': submitted && f.descriptionEn.errors }" ></angular-editor>


                      <div
                        *ngIf="submitted && f.descriptionEn.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.descriptionEn.errors.required">Champ obligatoire.</div>
                      </div>
                    </div>
      
                    <div class="form-group">
                      <label>Place</label>
                      <input
                        type="text"
                        formControlName="place"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.place.errors }"
                      />
                      <div
                        *ngIf="submitted && f.place.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.place.errors.required">Champ obligatoire.</div>
                      </div>
                    </div>
                      </div>
             
              
              <div *ngIf="fr" class="text-center">
                <button
                  class="btn btn-secondary mr-1"
                  type="reset"
                  (click)="onReset()"
                >
                  Annuler
                </button>
                <button class="btn btn-primary">Ajouter</button>
              </div>
              <div *ngIf="en" class="text-center">
                <button
                  class="btn btn-secondary mr-1"
                  type="reset"
                  (click)="onReset()"
                >
                  Cancel
                </button>
                <button class="btn btn-primary">Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
  
