import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { find, pull } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import {environment} from '../../../../../environments/environment'
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ProjectService } from 'src/app/_services/project.service';
declare var $: any;


@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  config: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    toolbarHiddenButtons: [
      [

        'italic',
        
      ],
      [
        'textColor',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
      ]
    ]
     
    
};


  categories = [
    {id: 1, name: "NOUVEAUTÉS"},
    {id: 2, name: "PROJETS"},
    {id: 3, name: "SHOWROOM"},
    {id: 4, name: "ÉVÉNEMENTS"},
    {id: 5, name: "INFORMATIONS"},
    {id: 6, name: "PRODUITS"},
  ];

  fixedProjectStructure :any;
  projectStructure:any;
  BackgroundImage:any;
  DeletedBackgroundImage: boolean =false;
  DeletedSection2Image: boolean =false;
  DeletedSection3Image: boolean =false;
  DeletedSection4Image: boolean =false;
  ImageSection2:any;
  ImageSection3:any;
  ImageSection4:any;
  isFR:boolean=true;
  isNL:boolean=false;
  isActiveSection1:boolean=true;
  isActiveSection2:boolean=false;
  isActiveSection3:boolean=false;
  isActiveSection4:boolean=false;
  myUUId:any;
  checked:boolean=false;
  checkedURL:boolean=false;


  products: any;
  ActiveSection: any;
  fixedProduct: any;
  searchproduct: string;
  projectId: any;
  ChoosenLanguage: any;
  BaseUrl = environment.baseUrl.split('/api')[0] + '/media/actualite/';
  ArrayImage :Array<any> = ['','','',''];



  @ViewChild('tagInput') tagInputRef: ElementRef;
  form: FormGroup;
  selectedPageContent : any;


  constructor(private toastr: ToastrService,
              // private replaceString: ReplacePipe,
              private actRoute: ActivatedRoute,
              private projectService: ProjectService,
              private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              // private homeService: HomePageService
              public iziToast: Ng2IzitoastService,

              ) {
    this.projectId = this.actRoute.snapshot.params['id'];
    this.ChoosenLanguage = 'FR';
  
    
  }

  ngOnInit(): void {
    
    this.ActiveSection = 1;
    this.projectService.get_project_by_id(this.projectId).subscribe(data => {
      this.fixedProjectStructure = data;
      this.projectStructure = data;
      this.myUUId = this.fixedProjectStructure['public_key'];
      this.BackgroundImage = this.BaseUrl + this.myUUId + "/"+this.fixedProjectStructure.background;
      if(!(this.projectStructure.sections[1].contents[0].content[1].elements[0]['img']) ||
      (this.projectStructure.sections[1].contents[0].content[1].elements[0]['img']).length == 0 ){
        this.ImageSection2 = this.BaseUrl+"default.png";

      }else{
        this.ImageSection2 = this.BaseUrl + this.myUUId + "/"+this.fixedProjectStructure.sections[1].contents[0].content[1].elements[0]['img'];
      }

      if(!(this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0]['img']) ||
      (this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0]['img']).length == 0){
        this.ImageSection3 = this.BaseUrl+"default.png";


      }else{
        this.ImageSection3 = this.BaseUrl + this.myUUId + "/"+this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0]['img'];
      }

      if(!(this.fixedProjectStructure.sections[3].contents[0].content[2].elements[0]['img']) || 
      (this.fixedProjectStructure.sections[3].contents[0].content[2].elements[0]['img']).length == 0
       ){
        this.ImageSection4 = this.BaseUrl+"default.png";
      }else{
        this.ImageSection4 =this.BaseUrl + this.myUUId + "/"+this.fixedProjectStructure.sections[3].contents[0].content[2].elements[0]['img'];


      }
    });
      this.fixedProduct = '';

    this.form = this.fb.group({
      tag: [undefined],
    });
    setTimeout(() => {
      this.get_page_content() 
    }, 8000);   
  }

  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.form.controls.tag.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Space') {
        this.addTag(inputValue);
        this.form.controls.tag.setValue('');
      }
    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'], tag)) {
      this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'].push(tag);
    }
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      pull(this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'], tag);
    } else {
      this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'].splice(-1);
    }
  }

  changeBackgroundSection(input) {
    if(input.files[0].size < 1*1024*1024)
    {
      var types = ["image/png", "image/gif", "image/jpeg"]
      if(types.indexOf(input.files[0].type) == -1){
        //this.toastr.error('L\'image doit être de type png, jpg ou jpeg. .')
        this.iziToast.show({
          message: 'L\'image doit être de type png, jpg ou jpeg.',
          messageColor:'#800f2f',
          titleColor:'#800f2f',
          progressBarColor:'#c9184a',
          icon:'bi-exclamation-diamond',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay:true,
          overlayClose:true,
          
        });
        return;
      }
    if (input.files && input.files[0]) {
      this.projectStructure.background = "Section_0.png";
      this.ArrayImage[0] =input.files[0];
      this.fixedProjectStructure.background = "Section_0.png";
      this.DeletedBackgroundImage = false;
      var reader = new FileReader();
      reader.onload = (e: any) => {
        let obj = e.target.result;
        this.BackgroundImage = obj;
      };
      reader.readAsDataURL(input.files[0]);
    }
  }else
  //this.toastr.error('Image très large! merci de ne pas dépasser 1 Mo');
  this.iziToast.show({
    message: 'Image très large! merci de ne pas dépasser 1 Mo.',
    messageColor:'#800f2f',
    titleColor:'#800f2f',
    progressBarColor:'#c9184a',
    icon:'bi-exclamation-diamond',
    imageWidth:45,
    position:'topRight',
    timeout:5000,
    backgroundColor:'#ff8fa3',
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX',
    overlay:true,
    overlayClose:true,
    
  });
  }

  addGalleryImg() {
    // @ts-ignore
    this.projectStructure.sections[3].contents[0].content[2].elements.push({img: ''});
  }

  addImg(input, arrayElementIndex) {
    if(input.files[0].size < 1*1024*1024)
    {
      var types = ["image/png", "image/gif", "image/jpeg"]
      if(types.indexOf(input.files[0].type) == -1){
        this.iziToast.show({
          message: 'Image très large! merci de ne pas dépasser 1 Mo.',
          messageColor:'#800f2f',
          titleColor:'#800f2f',
          progressBarColor:'#c9184a',
          icon:'bi-exclamation-diamond',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay:true,
          overlayClose:true,
          
        });
        //this.toastr.error('L\'Image très large! merci de ne pas dépasser 1 Mo.')
        
        return;
      }
    if (input.files && input.files[0]) {
      this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = input.files[0].name;
      // this.uploadBackground(input.files[0],input.files[0].name)
      var reader = new FileReader();
      reader.onload = (e: any) => {
        let obj = e.target.result;
        if(this.ActiveSection == 2){


          this.DeletedSection2Image = false;
          this.ArrayImage[1]=input.files[0];
          this.projectStructure.sections[1].contents[0].content[1].elements[0]['img'] = "Section_1.png";
          this.fixedProjectStructure.sections[1].contents[0].content[1].elements[0]['img'] = "Section_1.png";


          this.ImageSection2=obj;
        }else if(this.ActiveSection == 3 ){
          this.DeletedSection3Image = false;
          this.ArrayImage[2]=input.files[0];
          this.projectStructure.sections[2].contents[0].content[2].elements[0]['img'] = "Section_2.png";
          this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0]['img'] = "Section_2.png";

          this.ImageSection3=obj;
        }else{
          this.DeletedSection4Image = false;
          this.ArrayImage[3]=input.files[0];
          this.projectStructure.sections[3].contents[0].content[2].elements[0]['img'] = "Section_3.png";
          this.fixedProjectStructure.sections[3].contents[0].content[2].elements[0]['img'] = "Section_3.png";

          this.ImageSection4=obj
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  }else
  this.iziToast.show({
    message: 'Image très large! merci de ne pas dépasser 1 Mo.',
    messageColor:'#800f2f',
    titleColor:'#800f2f',
    progressBarColor:'#c9184a',
    icon:'bi-exclamation-diamond',
    imageWidth:45,
    position:'topRight',
    timeout:5000,
    backgroundColor:'#ff8fa3',
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX',
    overlay:true,
    overlayClose:true,
    
  });
  }

  // addProduct(productName: string) {
  //   this.searchproduct = '';
  //   // @ts-ignore
  //   this.projectStructure.sections[5].contents[0].content[1].elements.push({product: productName});

  // }


  // searchProduct() {
  //   this.products = this.fixedProduct.filter(x => {
  //     let name = x.s_modele_market.toLowerCase().toString();
  //     return name.includes(this.searchproduct.toLowerCase());
  //   });
  // }

  replaceUrl(value, strToReplace,replacementStr ){
    if (!value || !strToReplace || !replacementStr) {
      var new_value = value.replace(/[^a-z0-9]/g, "_");
      return new_value;
    }
    var new_valuer = value.replace(/[^a-z0-9 ]/g, "_");
    return new_valuer.replace(new RegExp(strToReplace, 'g'), replacementStr);
  }

  generateUrlPoject() {
    var newUrl  = this.replaceUrl(this.projectStructure.page_name.toLowerCase(),' ', '_');
    this.projectStructure.page_url = '/actualites/'+newUrl;
    this.projectStructure.page_url = (this.projectStructure.page_url[this.projectStructure.page_url.length - 1] == '_') ? this.projectStructure.page_url.slice(0, -1) : this.projectStructure.page_url;
  }
  get_page_content() {
    // this.homeService.get_page_content('home').subscribe(homeContent => {
    //   this.selectedPageContent = homeContent;
    // });
  }

  checkExist(){
    if(this.projectStructure.page_name && this.projectStructure.page_name != ""){
    this.projectService.check_actualite_existanceupadte(this.projectStructure.page_name, this.projectStructure._id).subscribe(resultat =>{
      if(resultat['message'] == "Exist"){
        this.checked =true;
      }
      else{
        this.checked= false;
      }
    })
  }
  else{
    this.checked=false;
  }
  }

  checkURLExist(){
    var url = (this.projectStructure.page_url).split('/')[2];
    if(url && url !=""){
      this.projectService.check_Urlactualite_existanceForupadte(url  , this.projectStructure._id).subscribe(resultat =>{
        if(resultat['message'] == "Exist"){
          this.checkedURL =true;
        }
        else{
          this.checkedURL= false;
        }
      })
    }

  }

  submit() {
    this.checkExist();
    let validProject = true;    
    this.projectStructure.description = this.projectStructure.sections[0].contents[0].content[1].elements[0]['description'];
    this.projectStructure.description_nl = this.projectStructure.sections[0].contents[1].content[1].elements[0]['description'];
    if (this.projectStructure.page_name == '' || this.projectStructure.description == '') {
      validProject = false;
      //this.toastr.error('Le titre et la description de la section couverture en français sont obligatoires.');
      this.iziToast.show({
        message: 'Le titre et la description de la section couverture en français sont obligatoires.',
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        icon:'bi-exclamation-diamond',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });

      return;
    }
    if(this.checked){
      validProject = false;
       //this.toastr.error('Le titre de l\'actualité en français est déja utilisé.');
       this.iziToast.show({
        message: 'Le titre de l\'actualité en français est déja utilisé.',
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        icon:'bi-exclamation-diamond',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });
      return;
    }

    if(this.checkedURL){
      validProject = false;
      this.iziToast.show({
        message: 'L\'Url de l\'actualité est conforme à une URL déja utilisé.',
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        icon:'bi-exclamation-diamond',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });
      return;
    }


    if (this.projectStructure.page_name_nl == '' ||  this.projectStructure.description_nl == '') {
      validProject = false;
      //this.toastr.error('Le titre et la description de la section couverture en anglais sont obligatoires.');
      this.iziToast.show({
        message: 'Le titre et la description de la section couverture en anglais sont obligatoires.',
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        icon:'bi-exclamation-diamond',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });

      return;
    }
    
    
    if (this.projectStructure.page_url.length < 7) {
      validProject = false;
      this.iziToast.show({
        message: 'L\'Url de page est court',
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        icon:'bi-exclamation-diamond',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });
      return;
    }
    if (this.projectStructure.background == '') {
      validProject = false;
      this.iziToast.show({
        message: 'L\'image de la section couverture est obligatoire',
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        icon:'bi-exclamation-diamond',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });
      return;
    }
    if (validProject) {
      this.uploadBackground(this.ArrayImage);
      if(this.DeletedSection2Image){
        this.projectService.deleteImage("Section_1.png", this.myUUId).subscribe((res)=>{})

      }
      if(this.DeletedSection3Image){
        this.projectService.deleteImage("Section_2.png", this.myUUId).subscribe((res)=>{})

      }
      if(this.DeletedSection4Image){
        this.projectService.deleteImage("Section_3.png", this.myUUId).subscribe((res)=>{})

      }
     

      if(this.selectedPageContent?.sections[9]?.contents[0]?.content[0]?.elements[0]?.actualities.length >0 && this.selectedPageContent?.sections[9]?.contents[0]?.content[0]?.elements[0]?.actualities.findIndex((obj => obj._id == this.projectId)) != -1){
      let objIndex = this.selectedPageContent?.sections[9]?.contents[0]?.content[0]?.elements[0]?.actualities.findIndex((obj => obj._id == this.projectId));
        this.selectedPageContent.sections[9].contents[0].content[0].elements[0].actualities[objIndex].page_name=this.projectStructure.page_name;
        this.selectedPageContent.sections[9].contents[0].content[0].elements[0].actualities[objIndex].page_url=this.projectStructure.page_url;
        this.selectedPageContent.sections[9].contents[0].content[0].elements[0].actualities[objIndex].description= this.projectStructure.description;

        // this.homeService.update_section_content('home', this.selectedPageContent.sections[9]).subscribe((data) => {
        // })
      }
      if(this.projectStructure.sections[1].title == "")
      this.projectStructure.sections[1].title = "section 2"
      if(this.projectStructure.sections[1].title_nl == "")
      this.projectStructure.sections[1].title_nl = "section 2"

      if(this.projectStructure.sections[2].title == "")
      this.projectStructure.sections[2].title = "section 3"
      if(this.projectStructure.sections[2].title_nl == "")
      this.projectStructure.sections[2].title_nl = "section 3"

      if(this.projectStructure.sections[3].title == "")
      this.projectStructure.sections[3].title = "section 4"
      if(this.projectStructure.sections[3].title_nl == "")
      this.projectStructure.sections[3].title_nl = "section 4"
      this.projectService.update_project(this.projectStructure).subscribe(data => {
       

        //this.selectedPageContent.sections[9].contents[0].content[0].elements[0].actualities()
        
       // this.toastr.success('L\'article a été modifié avec succès!');
        this.iziToast.show({
          message:'L\'actualité a été modifié avec succès',
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
           setTimeout(() => {
            this.router.navigate(['/admin/actualites']);
           }, 1000);
       
       
      }, (error) => {
        if (error.status == 200) {
          this.iziToast.show({
            message:'L\'actualité a été modifié avec succès',
            messageColor:'#386641',
            progressBarColor:'#6a994e',
            icon:'bi-check-circle',
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#dde5b6',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
             });
          this.router.navigate(['/admin/actualites']);
          setTimeout(() => {
           
          }, 1000);
        }
        else{
          this.toastr.error('L\'article n\'a pas été modifié avec succès!');
        }
      });
    }
  }

  generateBannerTitle() {
    this.projectStructure.sections[0].title = this.projectStructure.page_name;
    this.projectStructure.sections[0].contents[0].content[0].elements[0]['title'] = this.projectStructure.page_name;
  }
  generateBannerTitleNL() {
    this.projectStructure.sections[0].title_nl = this.projectStructure.page_name_nl;
    this.projectStructure.sections[0].contents[1].content[0].elements[0]['title'] = this.projectStructure.page_name_nl;
  }
  navigateListProject() {
    // if(this.DeletedBackgroundImage == true){
    //   this.toastr.error('L\'image de couverture est obligatoire!');
    //   return;
    // }else{
      this.router.navigate(['/admin/actualites']);
    // }
     
  }
  language(langue){
    if(langue == 'FR'){
      this.isFR =true;
      this.isNL =false;
    }else{
      this.isFR =false;
      this.isNL =true;

    }
    this.ChoosenLanguage=langue;
  }

  changesections(sectionName){
    if(sectionName == 1){
      this.isActiveSection2 =false;
      this.isActiveSection3 =false;
      this.isActiveSection4 =false;

      this.isActiveSection1 =true;

    }
    if(sectionName == 2){
      this.isActiveSection2 =true;
      this.isActiveSection3 =false;
      this.isActiveSection4 =false;
      this.isActiveSection1 =false;

    }
    if(sectionName == 3){
      this.isActiveSection2 =false;
      this.isActiveSection3 =true;
      this.isActiveSection4 =false;
      this.isActiveSection1 =false;

    }
    if(sectionName == 4){
      this.isActiveSection2 =false;
      this.isActiveSection3 =false;
      this.isActiveSection4 =true;
      this.isActiveSection1 =false;

    }
    this.ActiveSection =sectionName;
    }

    uploadBackground(ArrayImage){
      for(let v in ArrayImage ){
        if((ArrayImage[v])){

          var name = "Section_"+v+".png";
          const newFile: File = new File([this.ArrayImage[v]], name, {type: this.ArrayImage[v].type});
          const formData: FormData = new FormData();
          formData.append('files', newFile);
    
        this.projectService.uploadBackground(formData, this.myUUId).subscribe(
          event => {
            if(v ==  '1' && this.DeletedSection2Image == true){
              this.DeletedSection2Image = false
            }

            if(v ==  '2' && this.DeletedSection3Image == true){
              this.DeletedSection3Image = false
            }

            if(v ==  '3' && this.DeletedSection4Image == true){
              this.DeletedSection4Image = false
            }
            //if (event.type === HttpEventType.UploadProgress) {
            //this.progressInfos[1].value = Math.round(100 * event.loaded / event.total);
            //} else 
            
          },
          err => {
            this.toastr.error('Fichier ne peut pas être télécharger!',  ArrayImage[v].filename)
          });
  
        } 
      }
    }
    
    deleteImage(image){
      if(this.ActiveSection == 1){
        this.DeletedBackgroundImage =true;
        this.fixedProjectStructure.background = "";
      this.projectStructure.background = "";
      this.BackgroundImage = this.BaseUrl+"default.png";
      $("#photofrsec1").val('')

      }else
      {
         if(this.ActiveSection == 2){
          this.DeletedSection2Image =true;
          this.ImageSection2 = this.BaseUrl+"default.png";
           this.projectStructure.sections[1].contents[0].content[1].elements[0]['img'] = "";
           this.fixedProjectStructure.sections[1].contents[0].content[1].elements[0]['img'] = "";
        }else if(this.ActiveSection == 3){
          this.DeletedSection3Image = true;
          this.ImageSection3 = this.BaseUrl+"default.png";
          this.projectStructure.sections[2].contents[0].content[2].elements[0]['img'] = "";
          this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0]['img'] = "";
        }else{
          this.DeletedSection4Image = true;
          this.ImageSection4 = this.BaseUrl+"default.png";
          this.projectStructure.sections[3].contents[0].content[2].elements[0]['img'] = "";
          this.fixedProjectStructure.sections[3].contents[0].content[2].elements[0]['img'] = "";
        }
      // }, (err) =>{
      //   this.toastr.error("Erreur lors de la suppression du fichier!")
      // })
    }
      
    }

  

}
