import { Component, OnInit } from '@angular/core';
import { StatisticsService } from 'src/app/_services/statistics.service';

@Component({
  selector: 'app-dashbord',
  templateUrl: './dashbord.component.html',
  styleUrls: ['./dashbord.component.css']
})
export class DashbordComponent implements OnInit {
  numberTotalNews :Number
  numberPublishedNews :Number
  numberMaskedNews : Number
  numberTotalJobs :Number
  numberPublishedJobs :Number
  numberMaskedJobs : Number
  constructor(private statisticsService :StatisticsService) { }

  ngOnInit(): void {
    this.TotalNews()
    this.TotalPublishedNews()
    this.TotalMaskedNews()
    this.TotalJobs()
    this.TotalPublishedJobs()
    this.TotalMaskedJobs()
  }

  TotalNews()
  {
  this.statisticsService.numberTotalNews().subscribe(
      (e) => {
       this.numberTotalNews = e
      }
  )}

  TotalPublishedNews()
  {
  this.statisticsService.numberNewsPublished().subscribe(
      (e) => {
       this.numberPublishedNews = e
      }
  )}

  TotalMaskedNews()
  {
  this.statisticsService.numberNewsMasked().subscribe(
      (e) => {
       this.numberMaskedNews = e
      }
  )}

  TotalJobs()
  {
  this.statisticsService.numberTotalJobs().subscribe(
      (e) => {
       this.numberTotalJobs = e
      }
  )}

  TotalPublishedJobs()
  {
  this.statisticsService.numberJobsPublished().subscribe(
      (e) => {
       this.numberPublishedJobs = e
      }
  )}

  TotalMaskedJobs()
  {
  this.statisticsService.numberJobsMasked().subscribe(
      (e) => {
       this.numberMaskedJobs = e
      }
  )}



}
