import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { JobService } from 'src/app/_services/job.service';
import { StatisticsService } from 'src/app/_services/statistics.service';

@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.css']
})
export class AddJobComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  distrubters
  eroorMessage: string;
  fr =true
  en=false
  numberTotalJobs
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    width: "100%",
    minHeight: "5rem",
    placeholder: "",
    translate: "yes",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [
      [
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
      ]
    ],

    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };


  //types=[{type:"Premuni"},{type:"P"}]
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private jobService: JobService,
    public iziToast: Ng2IzitoastService,
    private statisticsService :StatisticsService


    ) {}

  ngOnInit() {
      this.registerForm = this.formBuilder.group({
        title : ['', Validators.required],
        titleEn : ['', Validators.required],
        subTitle : ['', Validators.required],
        subTitleEn : ['', Validators.required],
        description : ['', Validators.required],
        descriptionEn : ['', Validators.required],
        url :[''],
        place : ['', Validators.required],

      },
      
      );
      this.totalJobs()

  }
  get f() { return this.registerForm.controls; }

  save() {
    
    this.submitted = true;
    if(this.registerForm.value.title == "" || this.registerForm.value.subTitle == "" || this.registerForm.value.description == ""){
      this.iziToast.show({
        message: "Le titre, le sous-titre et la description en français sont obligatoires.",
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        //image:'icone',
        icon:'bi-exclamation-diamond',
        // icon:'bi-check-circle',
  
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });
      this.setLangueFrancais()
      return;
    }
   
    if(this.registerForm.value.titleEn == "" || this.registerForm.value.subTitleEn == "" || this.registerForm.value.descriptionEn == ""){
      this.iziToast.show({
        message: "Le titre, le sous-titre et la description en anglais sont obligatoires.",
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        //image:'icone',
        icon:'bi-exclamation-diamond',
        // icon:'bi-check-circle',
  
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });
      this.setLangueAnglais()
      return;
    }
   
    if (this.registerForm.invalid) {
     
      return;
    }
     
    let titleToUdapte = this.registerForm.value.title
    titleToUdapte = titleToUdapte.replace(/\W+(?!$)/g, '-').toLowerCase();
    titleToUdapte = titleToUdapte.replace(/\W$/, '').toLowerCase();
    this.numberTotalJobs =this.numberTotalJobs+1
    this.registerForm.value.url ="/emplois/"+this.numberTotalJobs+"/"+titleToUdapte
    this.jobService.createJob(this.registerForm.value).subscribe(
      (data) => {
       
        
        this.iziToast.show({
          message:"L'offre d'emploi est ajoutée avec succès.",
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
        this.goToList();
      },
      (error) => {
          
       
        this.eroorMessage=error.error
        this.iziToast.show({
          message: this.eroorMessage,
          messageColor:'#800f2f',
          titleColor:'#800f2f',
          progressBarColor:'#c9184a',
          //image:'icone',
          icon:'bi-exclamation-diamond',
          // icon:'bi-check-circle',
    
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay:true,
          overlayClose:true,
          
        });
      }
    );
   
  }
  goToList(){
    this.router.navigate(['/admin/emplois' ])
    }
  onSubmit() {
   
    this.save()
  }

  onReset() {
      this.submitted = false;
      this.registerForm.reset();
      this.router.navigate(['/admin/emplois' ])
  }

  setLangueFrancais()
   {
     this.fr=true;
     this.en=false;
   
   }
   setLangueAnglais()
   {
    this.fr=false;
    this.en=true;
  
   }

   totalJobs()
   {
   this.statisticsService.numberTotalJobs().subscribe(
       (e) => {
        this.numberTotalJobs = e
       }
   )}
 

}
