import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobService {

 private _JobURL=`${environment.baseUrl}/jobs`
 constructor(private http: HttpClient) { }

    getJobs() :Observable <any> {
     return this.http.get(`${this._JobURL}`);
     }

     createJob(job: any):Observable<Object> {
       return this.http.post(`${this._JobURL}`,job);
     }
     
     deleteJob(id : string)
     {
       return this.http.delete(`${this._JobURL}/${id}`);
     } 

    getJobById(id : string):Observable<any>
     {
       return this.http.get<any>(`${this._JobURL}/${id}`);
     } 
     
     editJob(id:string,job:any):Observable<Object> {
       return this.http.put(`${this._JobURL}/${id}`,job);
     }

     maskedOrDisplayJob(id : string)
     {
      return this.http.get(`${this._JobURL}/maskedOrDisplayJob/${id}`);
     }
    
  


}
