<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
      <div class="mb-npx">
          <div class="row align-items-center">
              <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                  <!-- Title -->
                  <h1 class="h2 mb-0 ls-tight" style="color:#868579">Actualités</h1>
              </div>
          </div>
          <!-- Nav -->
          <ul class="nav nav-tabs mt-4 overflow-x border-0">
              <li class="nav-item ">
                  <a  class="nav-link active"></a>
              </li>
              <li class="nav-item">
                  <a  class="nav-link font-regular"></a>
              </li>
              <li class="nav-item">
                  <a  class="nav-link font-regular"></a>
              </li>
          </ul>
      </div>
  </div>
</header>
<div class="cadre_scroll">
    <section class="content ecommerce-page">
      <!-- <div class="block-header">
        <div class="row">
          <div class="col-lg-7 col-md-6 col-sm-12">
            <h2>Modifier l'article
            </h2>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-12">
            <ul class="breadcrumb float-md-right">
              <li class="breadcrumb-item"><a routerLink="../../.."><i class="zmdi zmdi-home"></i>
                  Backoffice</a>
              </li>
              <li class="breadcrumb-item"><a routerLink="../../">Articles</a></li>
              <li class="breadcrumb-item active">Modifier</li>
            </ul>
          </div>
        </div>
      </div> -->
      <div class="container-fluid" *ngIf="projectStructure">
        <div class="row clearfix">
          <div class="col-lg-12">
            <div class="card">
              <div class="body">
                <h5 *ngIf="ChoosenLanguage =='FR'">Modifier une actualité</h5>
                <h5 *ngIf="ChoosenLanguage =='NL'">Edit news</h5>
                <br>
                <div class="row">
                  <div class="col-lg-12 col-12 project-listing">
                    <div class="tab-content">
                      <div class="tab-pane fade show active" id="list-cover" role="tabpanel"
                        aria-labelledby="list-cover-list">
                        <!--  <h6>Langues:</h6> -->
                        <div class="row mb-4">
                          <ng-container>
                            <button [ngClass]="isFR ? 'btn btn-admin col-1 current'  : 'btn btn-admin col-1   '"  style="text-transform: uppercase"
                              (click)="language('FR')" style="margin-right: 2px;">FR
                            </button>
                          </ng-container>
                          <button [ngClass]="isNL ? 'btn btn-admin col-1 current ' : 'btn btn-admin col-1 '"  style="text-transform: uppercase" (click)="language('NL')"
                            style="margin-left: 2px;">EN
                          </button>
                        </div>
  
                        <h5 *ngIf="ChoosenLanguage =='FR'">Informations générales </h5>
                        <h5 *ngIf="ChoosenLanguage =='NL'">General informations </h5>
  
                        <!-- show page projet FR -->
                        <div *ngIf="ChoosenLanguage =='FR'" class="form-group mb-4">
                          <div class="d-flex">
                            <label>Masquer l'article :
                              <input type="checkbox"  id="cb1" [(ngModel)]="projectStructure.hide_page"></label>
                          </div>
  
                        </div>
                        <!-- show page projet NL  -->
                        <div *ngIf="ChoosenLanguage =='NL'" class="form-group mb-4">
                          <div class="d-flex">
                            <label>Hide news :
                              <input type="checkbox"  id="cb1" [(ngModel)]="projectStructure.hide_page"></label>
                          </div>
  
                        </div>
                        <!-- titre page projet FR -->
                        <div *ngIf="ChoosenLanguage =='FR'" class="form-group mb-4">
                          <div class="d-flex"><label>Titre : </label>
                          </div>
  
                          <input type="text" class="form-control" [(ngModel)]="projectStructure.page_name"
                            placeholder="Titre"
                            (keyup)="generateUrlPoject();generateBannerTitle();checkExist();checkURLExist();">
                        </div>
                        <!-- titre page projet NL -->
                        <div *ngIf="ChoosenLanguage =='NL'" class="form-group mb-4">
                          <div class="d-flex"><label>Title : </label>
                          </div>
  
                          <input type="text" class="form-control" [(ngModel)]="projectStructure.page_name_nl"
                            placeholder="Title"
                            (keyup)="generateBannerTitleNL()">
                        </div>
  
                        <!-- url page projet -->
                        <div class="form-group mb-4" >
                          <div class="d-flex"><label>URL : </label>
                          </div>
  
                          <input type="email" class="form-control" [(ngModel)]="projectStructure.page_url"
                            placeholder="Titre" disabled>
                        </div>
                        <!-- description page projet -->
                        <div *ngIf="ChoosenLanguage =='FR'">
                          <hr />
                          <div class="form-group" hidden>
                            <label>Description :</label>
                            <angular-editor [placeholder]="'Description'"
                              [(ngModel)]="projectStructure.description"></angular-editor>
                          </div>
                        </div>
                        <!-- description page projet NL-->
                        <div *ngIf="ChoosenLanguage =='NL'">
                          <hr />
                          <div class="form-group" hidden>
                            <label>Description :</label>
                            <angular-editor [placeholder]="'Description'"
                              [(ngModel)]="projectStructure.description_nl"></angular-editor>
                          </div>
                        </div>
  
                        <h5 *ngIf="ChoosenLanguage =='FR'">Contenu :</h5>
                        <h5 *ngIf="ChoosenLanguage =='NL'">Content :</h5>
                        <!-- Fr LANGUE-->
  
                        <div  *ngIf="ChoosenLanguage =='FR'"  class="d-flex align-items-start">
                          <!-- Menu liste-->
                          <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <button   [ngClass]="isActiveSection1 ? 'nav-link  m-2 active' : 'nav-link  m-2 '"
                            
                             style="border:0" id="v-pills-banner-tab" type="button"
                              role="tab" aria-controls="v-pills-home" (click)="changesections(1)">Couverture
                            </button>
                            <button  [ngClass]="isActiveSection2 ? 'nav-link  m-2 active' : 'nav-link  m-2 '"
                             style="border:0" id="v-pills-les-attentes-du-client-tab"
                              type="button" role="tab" aria-controls="v-pills-home" (click)="changesections(2)">Section 2
                            </button>
                            <button 
                            [ngClass]="isActiveSection3 ? 'nav-link  m-2 active' : 'nav-link  m-2 '"
                            
                           style="border:0" id="v-pills-notre-approche-tab" type="button"
                              role="tab" aria-controls="v-pills-profile" (click)="changesections(3)">Section 3
                            </button>
                            <button 
                            [ngClass]="isActiveSection4 ? 'nav-link  m-2 active' : 'nav-link  m-2 '"
                           style="border:0" id="v-pills-le-résultat-final-tab" type="button"
                              role="tab" aria-controls="v-pills-messages" (click)="changesections(4)">Section 4
                            </button>
                            <!-- <button class="nav-link m-2" style="border:0" id="v-pills-meta-data-tab"
                                    data-bs-toggle="pill" data-bs-target="#v-pills-meta-data" type="button"
                                    role="tab" aria-controls="v-pills-messages" aria-selected="false">SEO
                            </button> -->
                          </div>
                          <div class="tab-content" id="v-pills-tabContent">
                            <!-- SECTION 1-->
                            <div *ngIf="ActiveSection === 1"  class="tab-pane fade show active" id="v-pills-banner" role="tabpanel"
                              aria-labelledby="v-pills-banner-tab">
  
  
                              <!--masquer section couverture-->
                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Masquer la section 1 - Couverture :
                                    <input type="checkbox"  id="cb1" [(ngModel)]="projectStructure?.sections[0].hide"></label>
                                </div>
  
                              </div>
  
                              <!-- titre page projet -->
                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Titre : </label>
                                </div>
  
                                <input type="text" class="form-control" [(ngModel)]="projectStructure.page_name"
                                  placeholder="Titre"
                                  (keyup)="generateUrlPoject();generateBannerTitle()">
                              </div>
  
                              <!-- catégorie de l'article -->
                              <div class="form-group mb-4" hidden>
                                <select [(ngModel)]="projectStructure.category" class="categorie">
                                  <option *ngFor="let category of categories" [ngValue]="category.name">{{category.name}}
                                  </option>
                                </select>
                              </div>
  
                              <!-- Arrière-plan page projet -->
                              <div class="form-group">
                                <hr />
                                <div class="row"><label class="col-2">Photo : </label>
                                  <picture class="col-4">
                                    <img class="img-fluid img-thumbnail" [src]="BackgroundImage"
                                     style="width: 15vw;height: auto;min-height: 200px;min-width: 200px;" alt="">
                                  </picture>
  
                                    <button  *ngIf="!DeletedBackgroundImage"  class="icon icon-shape  text-black text-md rounded-circle" (click)="deleteImage(projectStructure.background)"><i   class="bi bi-trash"></i></button>
                                    <input  *ngIf="DeletedBackgroundImage" type="file" class="form-control col-3"  id="photofrsec1"
                                    (change)="changeBackgroundSection($event.target)" style="height: 50px; margin-top: 10px;">
                                </div>
  
                              </div>
  
  
                              <div class="form-group">
                                <label>Description :</label>
                                <angular-editor [placeholder]="'Description ...'" [config]="config"
                                  [(ngModel)]="projectStructure?.sections[0]?.contents[0]?.content[1]?.elements[0]['description']">
                                </angular-editor>
                              </div>
                              <div class="form-group mb-4" hidden>
                                <div class="d-flex"><label>Bouton : </label>
                                </div>
  
                                <input type="text" class="form-control"
                                  [(ngModel)]="projectStructure?.sections[0]?.contents[0]?.content[2]?.elements[0]['btn_text']"
                                  placeholder="Titre">
                              </div>
  
                            </div>
                            <!-- End section 1 -->
                            <!-- SECTION 2-->
                            <div *ngIf="ActiveSection === 2" class="tab-pane fade show active" id="v-pills-les-attentes-du-client" role="tabpanel"
                              aria-labelledby="v-pills-les-attentes-du-client-tab">
  
                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Masquer la section 2 :
                                    <input type="checkbox"  id="cb1" [(ngModel)]="projectStructure?.sections[1].hide"></label>
                                </div>
                              </div>
  
                              <div class="ml-4">
  
                                <div class="form-group mb-4">
                                  <div class="d-flex"><label>Titre:</label>
                                  </div>
  
                                  <input type="text" class="form-control"
                                    [(ngModel)]="projectStructure?.sections[1].title"
                                    placeholder="Titre">
                                </div>
  
                                <div class="form-group">
                                  <label>Description :</label>
                                  <angular-editor [placeholder]="'Description ...'" [config]="config"
                                    [(ngModel)]="projectStructure?.sections[1]?.contents[0]?.content[0]?.elements[0]['description']">
                                  </angular-editor>
                                </div>
  
  
                                <div class="form-group">
                                  <hr />
                                  <div class="row"><label class="col-2">Photo : </label>
                                    <picture class="col-4">
                                      <img class="img-fluid img-thumbnail"
                                        [src]="ImageSection2"
                                       style="width: 15vw;height: auto;min-height: 200px;min-width: 200px;" alt="">
                                    </picture>
                                    <button  *ngIf="ImageSection2.indexOf('default.png') < 0 " class="icon icon-shape  text-black text-md rounded-circle" (click)="deleteImage(projectStructure.sections[1].contents[0].content[1].elements[0]['img'])">
                                      <i *ngIf="projectStructure.sections[1].contents[0].content[1].elements[0]['img'] !=''" class="bi bi-trash"></i></button>
                                    <input *ngIf="ImageSection2.indexOf('default.png') > 0 " type="file" class="form-control col-3"
                                      (change)="addImg($event.target,[1,0,1,0])" style="height: 50px ;margin-top: 10px;">
                                  </div>
  
                                </div>
  
                                <div class="form-group" >
                                  <label>Paragraphe 1 :</label>
                                  <angular-editor [placeholder]="'Paragraphe 1...'" [config]="config"
                                    [(ngModel)]="projectStructure?.sections[1]?.contents[0]?.content[2]?.elements[0]['description']">
                                  </angular-editor>
                                </div>
  
                                <div class="form-group" >
                                  <label>Paragraphe 2 :</label>
                                  <angular-editor [placeholder]="'Paragraphe 2...'" [config]="config"
                                    [(ngModel)]="projectStructure?.sections[1]?.contents[0]?.content[2]?.elements[1]['description']">
                                  </angular-editor>
                                </div>
  
                              </div>
  
                            </div>
                            <!-- End section 2 -->
                            <!-- SECTION 3-->
                            <div *ngIf="ActiveSection === 3"  class="tab-pane fade show active" id="v-pills-notre-approche" role="tabpanel"
                              aria-labelledby="v-pills-notre-approche-tab">
  
                              <div class="form-group mb-4">
                                <div class="d-flex"> <label>Masquer la section 3 :
                                    <input type="checkbox"  id="cb1" [(ngModel)]="projectStructure?.sections[2].hide"></label>
                                </div>
                              </div>
  
                              <div class="ml-4">
                                <div class="form-group mb-4">
                                  <div class="d-flex"><label>Titre:</label>
                                  </div>
  
                                  <input type="text" class="form-control"
                                    [(ngModel)]="projectStructure?.sections[2].title"
                                    placeholder="Titre">
                                </div>
  
                                <div class="form-group">
                                  <label>Description :</label>
                                  <angular-editor [placeholder]="'Description...'" [config]="config"
                                    [(ngModel)]="projectStructure?.sections[2]?.contents[0]?.content[1]?.elements[0]['description']">
                                  </angular-editor>
                                </div>
  
                                <div class="form-group">
                                  <div class="row"><label class="col-2">Photo : </label>
                                    <picture class="col-4">
                                      <img class="img-fluid img-thumbnail"
                                        [src]="ImageSection3"
                                       style="width: 15vw;height: auto;min-height: 200px;min-width: 200px;" alt="">
                                    </picture>
                                    <button *ngIf="ImageSection3.indexOf('default.png') < 0 " class="icon icon-shape  text-black text-md rounded-circle" (click)="deleteImage(projectStructure.sections[2].contents[0].content[2].elements[0]['img'])">
                                      <i *ngIf="projectStructure.sections[2].contents[0].content[2].elements[0]['img'] !=''" class="bi bi-trash"></i></button>
                                    <input *ngIf="ImageSection3.indexOf('default.png') > 0 " type="file" class="form-control col-3"
                                      (change)="addImg($event.target,[2,0,2,0])" style="height: 50px;margin-top: 10px;">
                                  </div>
  
                                </div>
  
                              </div>
  
                            </div>
                            <!-- End section 3 -->
                            <!-- SECTION 4-->
                            <div *ngIf="ActiveSection == 4" class="tab-pane fade show active" id="v-pills-le-résultat-final" role="tabpanel"
                              aria-labelledby="v-pills-notre-approche-tab">
  
                              <div class="form-group mb-4">
                                <div class="d-flex"> <label>Masquer la section 4:
                                    <input type="checkbox"  id="cb1" [(ngModel)]="projectStructure?.sections[3].hide"></label>
                                </div>
                              </div>
  
                              <div class="ml-4">
                                <div class="form-group mb-4">
                                  <div class="d-flex"><label>Titre:</label>
                                  </div>
  
                                  <input type="text" class="form-control"
                                    [(ngModel)]="projectStructure?.sections[3].title"
                                    placeholder="Titre">
                                </div>
  
                                <div class="form-group">
                                  <label>Description :</label>
                                  <angular-editor [placeholder]="'Description...'" [config]="config"
                                    [(ngModel)]="projectStructure?.sections[3]?.contents[0]?.content[1]?.elements[0]['description']">
                                  </angular-editor>
                                </div>
  
                                <div class="form-group">
                                  <div class="row"><label class="col-2">Photo : </label>
                                    <picture class="col-4">
                                      <img class="img-fluid img-thumbnail"
                                        [src]="ImageSection4"
                                       style="width: 15vw;height: auto;min-height: 200px;min-width: 200px;" alt="">
                                    </picture>
                                    <button *ngIf="ImageSection4.indexOf('default.png') < 0 " class="icon icon-shape  text-black text-md rounded-circle" (click)="deleteImage(projectStructure.sections[3].contents[0].content[2].elements[0]['img'])">
                                      <i *ngIf="projectStructure.sections[3].contents[0].content[2].elements[0]['img'] != ''" class="bi bi-trash"></i></button>
  
                                    <input *ngIf="ImageSection4.indexOf('default.png') > 0 " type="file" class="form-control col-3"
                                      (change)="addImg($event.target,[3,0,2,0])" style="height: 50px;margin-top: 10px;">
                                  </div>
  
                                </div>
  
                              </div>
  
                            </div>
                            <!-- End section 4 -->
  
                          </div>
                        </div>
                        <!-- NL LANGUE-->
                        <div  *ngIf="ChoosenLanguage =='NL'"  class="d-flex align-items-start">
                          <!-- Menu liste-->
                          <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                          aria-orientation="vertical">
                          <button  [ngClass]="isActiveSection1 ? 'nav-link  m-2 active' : 'nav-link  m-2 '"  style="border:0" id="v-pills-banner-tab" type="button"
                            role="tab" aria-controls="v-pills-home" (click)="changesections(1)">
                            Coverage
                          </button>
                          <button [ngClass]="isActiveSection2 ? 'nav-link  m-2 active' : 'nav-link  m-2 '" style="border:0" id="v-pills-les-attentes-du-client-tab"
                            type="button" role="tab" aria-controls="v-pills-home" (click)="changesections(2)">Section 2
                          </button>
                          <button [ngClass]="isActiveSection3 ? 'nav-link  m-2 active' : 'nav-link  m-2 '" style="border:0" id="v-pills-notre-approche-tab" type="button"
                            role="tab" aria-controls="v-pills-profile" (click)="changesections(3)">Section 3
                          </button>
                          <button [ngClass]="isActiveSection4 ? 'nav-link  m-2 active' : 'nav-link  m-2 '" style="border:0" id="v-pills-le-résultat-final-tab" type="button"
                            role="tab" aria-controls="v-pills-messages" (click)="changesections(4)">Section 4
                          </button>
                          <!-- <button class="nav-link m-2" style="border:0" id="v-pills-meta-data-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-meta-data" type="button"
                                  role="tab" aria-controls="v-pills-messages" aria-selected="false">SEO
                          </button> -->
                        </div>
                          <div class="tab-content" id="v-pills-tabContent">
                            <!-- SECTION 1-->
                            <div *ngIf="ActiveSection === 1"  class="tab-pane fade show active" id="v-pills-banner" role="tabpanel">
                              <!--masquer section couverture-->
                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Hide section 1 - Coverage :
                                    <input type="checkbox"  id="cb1" [(ngModel)]="projectStructure?.sections[0].hide"></label>
                                </div>
  
                              </div>
  
                              <!-- titre page projet -->
                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Title : </label>
                                </div>
  
                                <input type="text" class="form-control" [(ngModel)]="projectStructure.page_name_nl"
                                  placeholder="Title"
                                  (keyup)="generateBannerTitle()">
                              </div>
  
                              <!-- catégorie de l'article -->
                              <div class="form-group mb-4" hidden>
                                <select [(ngModel)]="projectStructure.category" class="categorie">
                                  <option *ngFor="let category of categories" [ngValue]="category.name">{{category.name}}
                                  </option>
                                </select>
                              </div>
  
                              <!-- Arrière-plan page projet -->
                              <div class="form-group">
                                <hr />
                                <div class="row"><label class="col-2">Photo : </label>
                                  <picture class="col-4">
                                    <img class="img-fluid img-thumbnail" [src]="BackgroundImage"
                                     style="width: 15vw;height: auto;min-height: 200px;min-width: 200px;" alt="">
                                  </picture>
                                  <button *ngIf="!DeletedBackgroundImage" class="icon icon-shape  text-black text-md rounded-circle" (click)="deleteImage(projectStructure.background)"><i class="bi bi-trash"></i></button>
                                  <input *ngIf="DeletedBackgroundImage" type="file" class="form-control col-3" id="photofrsec1"
                                    (change)="changeBackgroundSection($event.target)" style="height: 50px;margin-top: 10px;">
                                </div>
  
                              </div>
  
  
                              <div class="form-group">
                                <label>Description : </label>
                                <angular-editor [placeholder]="'Description...'" [config]="config"
                                  [(ngModel)]="projectStructure?.sections[0]?.contents[1]?.content[1]?.elements[0]['description']">
                                </angular-editor>
                              </div>
                              <div class="form-group mb-4" hidden>
                                <div class="d-flex"><label>Knop:</label>
                                </div>
  
                                <input type="text" class="form-control"
                                  [(ngModel)]="projectStructure?.sections[0]?.contents[1]?.content[2]?.elements[0]['btn_text']"
                                  placeholder="Title">
                              </div>
  
                            </div>
                            <!-- End section 1 -->
                            <!-- SECTION 2-->
                            <div *ngIf="ActiveSection === 2" class="tab-pane fade show active" id="v-pills-les-attentes-du-client" role="tabpanel"
                              aria-labelledby="v-pills-les-attentes-du-client-tab">
  
                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Hide section 2 :
                                    <input type="checkbox"  id="cb1" [(ngModel)]="projectStructure?.sections[1].hide"></label>
                                </div>
                              </div>
  
                              <div class="ml-4">
  
                                <div class="form-group mb-4">
                                  <div class="d-flex"><label>Title :</label>
                                  </div>
  
                                  <input type="text" class="form-control"
                                    [(ngModel)]="projectStructure?.sections[1].title_nl"
                                    placeholder="Title">
                                </div>
  
                                <div class="form-group">
                                  <label>Description :</label>
                                  <angular-editor [placeholder]="'Description...'" [config]="config"
                                    [(ngModel)]="projectStructure?.sections[1]?.contents[1]?.content[0]?.elements[0]['description']">
                                  </angular-editor>
                                </div>
  
  
                                <div class="form-group">
                                  <hr />
                                  <div class="row"><label class="col-2">Photo : </label>
                                    <picture class="col-4">
                                      <img class="img-fluid img-thumbnail"
                                        [src]="ImageSection2"
                                       style="width: 15vw;height: auto;min-height: 200px;min-width: 200px;" alt="">
                                    </picture>
                                    <button *ngIf="ImageSection2.indexOf('default.png') < 0 " class="icon icon-shape  text-black text-md rounded-circle" (click)="deleteImage(projectStructure.sections[1].contents[0].content[1].elements[0]['img'])">
                                      <i *ngIf="projectStructure.sections[1].contents[0].content[1].elements[0]['img'] !=''" class="bi bi-trash"></i></button>
                                    
                                    <input *ngIf="ImageSection2.indexOf('default.png') > 0 " type="file" class="form-control col-3"
                                      (change)="addImg($event.target,[1,0,1,0])" style="height: 50px;margin-top: 10px;">
                                  </div>
  
                                </div>
  
                                <div class="form-group" >
                                  <label>Paragraph 1 :</label>
                                  <angular-editor [placeholder]="'Paragraph 1...'"
                                    [(ngModel)]="projectStructure?.sections[1]?.contents[1]?.content[2]?.elements[0]['description']">
                                  </angular-editor>
                                </div>
  
                                <div class="form-group" >
                                  <label>Paragraph 2 :</label>
                                  <angular-editor [placeholder]="'Paragraph 2...'"
                                    [(ngModel)]="projectStructure?.sections[1]?.contents[1]?.content[2]?.elements[1]['description']">
                                  </angular-editor>
                                </div>
  
                              </div>
  
                            </div>
                            <!-- End section 2 -->
                            <!-- SECTION 3-->
                            <div *ngIf="ActiveSection === 3"  class="tab-pane fade show active" id="v-pills-notre-approche" role="tabpanel"
                              aria-labelledby="v-pills-notre-approche-tab">
  
                              <div class="form-group mb-4">
                                <div class="d-flex"> <label>Hide section 3 :
                                    <input type="checkbox"  id="cb1" [(ngModel)]="projectStructure?.sections[2].hide"></label>
                                </div>
                              </div>
  
                              <div class="ml-4">
                                <div class="form-group mb-4">
                                  <div class="d-flex"><label>Titel:</label>
                                  </div>
  
                                  <input type="text" class="form-control"
                                    [(ngModel)]="projectStructure?.sections[2].title_nl"
                                    placeholder="Title">
                                </div>
  
                                <div class="form-group">
                                  <label>Description :</label>
                                  <angular-editor [placeholder]="'Description...'" [config]="config"
                                    [(ngModel)]="projectStructure?.sections[2]?.contents[1]?.content[1]?.elements[0]['description']">
                                  </angular-editor>
                                </div>
  
                                <div class="form-group">
                                  <div class="row"><label class="col-2">Photo : </label>
                                    <picture class="col-4">
                                      <img class="img-fluid img-thumbnail"
                                        [src]="ImageSection3"
                                       style="width: 15vw;height: auto;min-height: 200px;min-width: 200px;" alt="">
                                    </picture>
                                    <button *ngIf="ImageSection3.indexOf('default.png') < 0 
                                    
                                    " class="icon icon-shape  text-black text-md rounded-circle" (click)="deleteImage(projectStructure.sections[2].contents[0].content[2].elements[0]['img'])">
                                      <i *ngIf="projectStructure.sections[2].contents[0].content[2].elements[0]['img'] !=''" class="bi bi-trash"></i></button>
                                    <input *ngIf="ImageSection3.indexOf('default.png') > 0 " type="file" class="form-control col-3"
                                      (change)="addImg($event.target,[2,0,2,0])" style="height: 50px;margin-top: 10px;">
                                  </div>
  
                                </div>
  
                              </div>
  
                            </div>
                            <!-- End section 3 -->
                            <!-- SECTION 4-->
                            <div *ngIf="ActiveSection == 4" class="tab-pane fade show active" id="v-pills-le-résultat-final" role="tabpanel">
  
                              <div class="form-group mb-4">
                                <div class="d-flex"> <label>Hide section 4:
                                    <input type="checkbox"  id="cb1" [(ngModel)]="projectStructure?.sections[3].hide"></label>
                                </div>
                              </div>
  
                              <div class="ml-4">
                                <div class="form-group mb-4">
                                  <div class="d-flex"><label>Title:</label>
                                  </div>
  
                                  <input type="text" class="form-control"
                                    [(ngModel)]="projectStructure?.sections[3].title_nl"
                                    placeholder="Title">
                                </div>
  
                                <div class="form-group">
                                  <label>Description :</label>
                                  <angular-editor [placeholder]="'Description...'" [config]="config"
                                    [(ngModel)]="projectStructure?.sections[3]?.contents[1]?.content[1]?.elements[0]['description']">
                                  </angular-editor>
                                </div>
  
                                <div class="form-group">
                                  <div class="row"><label class="col-2">Photo : </label>
                                    <picture class="col-4">
                                      <img class="img-fluid img-thumbnail"
                                        [src]="ImageSection4"
                                       style="width: 15vw;height: auto;min-height: 200px;min-width: 200px;" alt="">
                                    </picture>
                                    <button *ngIf="ImageSection4.indexOf('default.png') < 0 " class="col-3 icon icon-shape  text-black text-md rounded-circle" (click)="deleteImage(projectStructure.sections[3].contents[0].content[2].elements[0]['img'])">
                                      <i *ngIf="projectStructure.sections[3].contents[0].content[2].elements[0]['img'] != ''" class="bi bi-trash"></i></button>
                                    <input *ngIf="ImageSection4.indexOf('default.png') > 0 " type="file" class="form-control col-3"
                                      (change)="addImg($event.target,[3,0,2,0])" style="height: 50px;margin-top: 10px;">
                                  </div>
  
                                </div>
  
                              </div>
  
                            </div>
                            <!-- End section 4 -->
  
                          </div>
                        </div>
  
                        <hr />   
                       
                              <div class="modal-footer">
                              </div>
                        <div *ngIf="ChoosenLanguage =='FR'" class="text-center">
                        
                          <button class= " btn btn-secondary mr-1" (click)="navigateListProject()" type="button">Annuler
                          </button>
                        
                      
                          <button class="btn btn-primary" value="submit" type="button" (click)="submit()">Valider
                          </button>
                        
                      </div>

                      <div *ngIf="ChoosenLanguage =='NL'" class="text-center">
                      
                        <button class= " btn btn-secondary mr-1" (click)="navigateListProject()" type="button">Cancel
                        </button>
                      
                    
                        <button class="btn btn-primary" value="submit" type="button" (click)="submit()">Validate
                        </button>
                      
                    </div>
                        <!-- **** -->
                      </div>
                    </div>
                  </div>
                </div>
  
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>