<header class="bg-surface-primary border-bottom pt-6">
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-sm-6 col-6 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight" style="color:#868579">Actualités</h1>
        </div>
        <div class="col-sm-6 col-6 mb-4 mb-sm-0">
          <!-- Title -->
          <button
          style="
          background: #62B9CB!important;
          color: #fff!important;
          float: right;
        "
          class="btn btn-secondary"
            routerLink="/admin/actualites/ajout"
          >
            Ajouter
          </button>
        </div>
        <!-- Actions -->
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <li class="nav-item">
          <a class="nav-link active"> </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active"> </a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link font-regular"></a>
        </li>
      </ul>
    </div>
  </div>
</header>

<main class="py-6 bg-surface-secondary">
  <div class="container-fluid">
    <div class="card shadow border-0 mb-7">
      <div class="card-header text-center">
        <ul id="portfolio-flters">
          <li *ngIf="AllActive ==true" style=" font-size: 16px;" class="filter-active">Toutes </li>
          <li (click)="GetAll()" *ngIf="AllActive ==false" style=" font-size: 16px;" class="">Toutes</li>
          <li  *ngIf="AllPublished ==true" style=" font-size: 16px;" class="filter-active">Publiée(s)</li>
          <li (click)="GetPublished()" *ngIf="AllPublished ==false" style=" font-size: 16px;" class="">Publiée(s)</li>
          <li *ngIf="AllHidden ==true" class="filter-active" style=" font-size: 16px;">Masquée(s)</li>
          <li (click)="GetHidden()" *ngIf="AllHidden ==false" style=" font-size: 16px;" class="">Masquée(s)</li>
        </ul>
      </div>

      <div class="container py-5">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" *ngFor="let project of projectslist | paginate
          : {
              itemsPerPage: tableSize,
              currentPage: page,
              totalItems: count
            };
      let i = index"
 >
            <div class="card zoom" style="height: 310px; cursor: pointer;" >
              <img
                src="{{ BaseUrl }}{{project.public_key}}/{{ project.background }}"
                class="image"
                alt=""
                style="max-height: 209px;min-height: 209px;"
              />
              <div class="card-body">
                <div class="colum">
                  <div > <h5 class="card-title" style="margin-bottom: 2% ;text-transform: capitalize" >
                    {{ (project.page_name.length>40)? (project.page_name | slice:0:30)+'...':(project.page_name) }}
                   
                  </h5>
                   </div>
                  <div class="col" style="text-align: center !important;">


                  <p class="card-text description">

                    <a
                      data-toggle="modal"
                      data-target="#exampleModal"
                      (click)="setObjectTotrait(project);setHideMessage(project)"
                    >
                      <i
                        *ngIf="!project.hide_page"
                        class="fa fa-eye-slash"
                        style="cursor: pointer"
                        title="Masquer"
                      ></i>
                      <i
                        *ngIf="project.hide_page"
                        class="fa fa-eye"
                        style="cursor: pointer"
                        title="Afficher"
                      ></i>
                    </a>
                    &nbsp;&nbsp;
                    <a (click)="UpdatePage(project._id)">
                      <i
                        class="fa fa-edit"
                        style="cursor: pointer"
                        title="Modifier"
                      ></i
                    ></a>
                    &nbsp;&nbsp;
                    <a>
                      <i
                        *ngIf="!project.deleted_page"
                        class="fa fa-trash"
                        data-toggle="modal"
                        data-target="#exampleModall"
                        (click)="setObjectTotrait(project);setDeleteMessage(project)"
                        style="cursor: pointer"
                        title="Supprimer"
                      ></i>
                      <i
                        *ngIf="project.deleted_page"
                        class="fa fa-reply"
                        data-toggle="modal"
                        data-target="#exampleModall"
                        (click)="setObjectTotrait(project);setDeleteMessage(project)"
                        style="cursor: pointer"
                        title="Remettre"
                      ></i>
                    </a>
                  </p>
                  </div>
                 
                  
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <br /><br /><br /><br /><br />
            </div>
          </div>
        
          <div
          
          class="card-footer border-0 py-5"
        >
          <div class="d-flex justify-content-center" >
          
            <pagination-controls 
              class="my-pagination"
              previousLabel="Previous"
              nextLabel="Suivant"
              (pageChange)="onTableDataChange($event)"
            >
            </pagination-controls>
          </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>
</main>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 style="color: #868579;" *ngIf="HideAction==true" class="modal-title" id="exampleModalLabel">
          Confirmation de masquage
        </h2>
        <h2 style="color: #868579;" *ngIf="DisplayAction==true" class="modal-title" id="exampleModalLabel">
          Confirmation de récupération
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-4">
            <img src="assets/images/1.jpg" alt="" />
          </div>
          <div class="col-8">
            <h3  *ngIf="HideAction==true"  style="position: relative; top: 40px !important;color: #868579;">
              Êtes-vous sûr(e) de vouloir masquer cette actualité ?
            </h3>
            <h3 *ngIf="DisplayAction==true" style="position: relative; top: 40px !important;color: #868579;">
              Êtes-vous sûr(e) de vouloir réafficher cette actualité ?
            </h3>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          style="
            background: white!important;
            color: #62B9CB!important;
            border: 1px solid #62B9CB!important;
          "
          class="btn btn-secondary"
          (click)="Annuler()"
          data-dismiss="modal"
        >
          Annuler
        </button>
        <button
        style="
        background: #62B9CB!important;
        color: #fff!important;
      "
        class="btn btn-add "
        data-dismiss="modal"
          (click)="hideActialite('project')"
        >
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModall"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabell"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 style="color: #868579;" *ngIf="DeleteAction==true" class="modal-title" id="exampleModalLabel">
          Confirmation de suppression
        </h2>
        <h2 style="color: #868579;" *ngIf="RecoverAction==true" class="modal-title" id="exampleModalLabel">
          Confirmation de récupération
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="DeleteAction==true" class="modal-body">
        <div class="row">
          <div class="col-4"><img src="assets/images/delete.png" alt="" /></div>
          <div class="col-8">
            <h3 style="position: relative; top: 40px !important;color: #868579;">
              Êtes-vous sûr(e) de vouloir supprimer cette actualité?
            </h3>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="HideAction==true" class="modal-body">
        <div class="row">
          <div class="col-4"><img src="assets/images/wondering.png" alt="" /></div>
          <div class="col-8">
            <h3 style="position: relative; top: 40px !important">
              Êtes-vous sûr(e) de vouloir masquer cet article?
            </h3>
          </div>
        </div>
      </div> -->
      <!-- <div *ngIf="DisplayAction==true" class="modal-body">
        <div class="row">
          <div class="col-4"><img src="assets/images/wondering.png" alt="" /></div>
          <div class="col-8">
            <h3 style="position: relative; top: 40px !important">
              Êtes-vous sûr(e) de vouloir réafficher cet article?
            </h3>
          </div>
        </div>
      </div> -->
      <div *ngIf="RecoverAction==true" class="modal-body">
        <div class="row">
          <div class="col-4"><img src="assets/images/delete.png" alt="" /></div>
          <div class="col-8">
            <h3 style="position: relative; top: 40px !important;color: #868579;">
              Êtes-vous sûr(e) de vouloir récupérer cette actualité?
            </h3>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          style="
          background: white!important;
          color: #62B9CB!important;
          border: 1px solid #62B9CB!important;
        "
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="Annuler()"
        >
          Annuler
        </button>
        <button
          type="button"
          style="
          background: #62B9CB!important;
          color: #fff!important;
        "
          class="btn btn-add "
          data-dismiss="modal"
          (click)="deleteActialite('project')"
        >
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>
