import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  private _statisticsURL=`${environment.baseUrl}/statistics`
  constructor(private http: HttpClient) { }

  numberTotalNews():Observable <any> {
    return this.http.get(`${this._statisticsURL}/totalNumberNews`);
        }

  numberNewsPublished():Observable <any> {
      return this.http.get(`${this._statisticsURL}/publishedNumberNews`);
        }
     
   numberNewsMasked():Observable <any> {
        return this.http.get(`${this._statisticsURL}/maskedNumberNews`);
        }     
           
  numberTotalJobs():Observable <any> {
      return this.http.get(`${this._statisticsURL}/totalNumberJobs`);
        }
      
  numberJobsPublished():Observable <any> {
        return this.http.get(`${this._statisticsURL}/publishedNumberJobs`);
        }
           
  numberJobsMasked():Observable <any> {
        return this.http.get(`${this._statisticsURL}/maskedNumberJobs`);
        }          


}
