<header class="bg-surface-primary border-bottom pt-6">
    <div class="container-fluid">
        <div class="mb-npx">
            <div class="row align-items-center">
                <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                    <!-- Title -->
                    <h1 class="h2 mb-0 ls-tight" style="color:#868579">Emplois</h1>
                </div>
                <div class="col-sm-6 col-6 mb-4 mb-sm-0">
                    <!-- Title -->
                    <button
                    style="
                    background: #62B9CB!important;
                    color: #fff!important;
                    float: right;
                  "
                    class="btn btn-secondary"
                      routerLink="/admin/emplois/ajout"
                    >
                      Ajouter
                    </button>
                  </div>
            </div>
            <!-- Nav -->
            <ul class="nav nav-tabs mt-4 overflow-x border-0">
                <li class="nav-item ">
                    <a  class="nav-link active"></a>
                </li>
                <li class="nav-item">
                    <a  class="nav-link font-regular"></a>
                </li>
                <li class="nav-item">
                    <a  class="nav-link font-regular"></a>
                </li>
            </ul>
        </div>
    </div>
  </header>
<!-- Section-->
<section class="py-5">
    <div class="container px-4 px-lg-5 mt-5">
        <div  class="row gx-4 gx-lg-5 row-cols-1 row-cols-sm-2  row-cols-md-3 row-cols-lg-3 row-cols-xl-3 justify-content-first">
            <div *ngFor="let job of jobs" class="col mb-5">
                <div class="card h-100">
                     <!-- Sale badge-->
                    <div *ngIf="job.publish" class="badge bg-primary text-white position-absolute" style="top: 0.5rem; right: 0.5rem">publiée</div>
                    <div *ngIf="!job.publish" class="badge bg-secondary text-white position-absolute" style="top: 0.5rem; right: 0.5rem">masquée</div>
                    <!-- Product image-->
                    <img class="card-img-top" src="https://www.cannes.com/_richText-file/ametys-internal%253Asites/www/ametys-internal%253Acontents/nouvel-article-133/_attribute/content/_data/emploi-recrutement-cannes_326x592.png" alt="..." />
                    <!-- Product details-->
                    
                    <div class="card-body p-4">
                        <div class="text-center">
                            <!-- Product name-->
                            <h5 class="fw-bolder">{{job.title}}</h5>
                            <!-- Product price-->
                            <p style="color: #868579">{{job.subTitle}}</p>
                          
                            <p style="margin-top: 2%;" [innerHTML]="job.description"></p>
                        </div>
                    </div>
                    <!-- Product actions-->
                    <div class="card-footer p-4 pt-0 border-top-0 bg-transparent">
                        <div class="text-center">
                          <a   
                            class="btn btn-primary mt-auto mr-1"
                            data-toggle="modal"
                            data-target="#exampleModal" 
                            (click)="getJobToMaskedOrDisplay(job)"
                            >
                              <i  *ngIf="job.publish"
                                class="fa fa-eye-slash"
                                style="cursor: pointer"
                                title="Masquer">
                               </i>
                              <i *ngIf="!job.publish"
                                class="fa fa-eye"
                                style="cursor: pointer"
                                title="Afficher">
                              </i>
                          </a>
                            <a class="btn btn-primary mt-auto mr-1"> <i
                                class="fa fa-edit"
                                style="cursor: pointer"
                                title="Modifier"></i>
                              </a>
                            <a class="btn btn-primary mt-auto mr-1" > <i
                                (click)="getJobToDelted(job)"
                                class="fa fa-trash"
                                style="cursor: pointer"
                                title="Supprimer"
                                data-toggle="modal"
                                data-target="#exampleModall"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
           
      
        </div>
    </div>
</section>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModall"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabell"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 style="color: #868579;"  class="modal-title" id="exampleModalLabel">
          Confirmation de suppression
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div  class="modal-body">
        <div class="row">
          <div class="col-4"><img src="assets/images/delete.png" alt="" /></div>
          <div class="col-8">
            <h3 style="position: relative; top: 40px !important;color: #868579;">
              Êtes-vous sûr(e) de vouloir supprimer cette offre d'emploi ?
            </h3>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          style="
          background: white!important;
          color: #62B9CB!important;
          border: 1px solid #62B9CB!important;
        "
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="getJobs()"
        >
          Annuler
        </button>
        <button
          type="button"
          style="
          background: #62B9CB!important;
          color: #fff!important;
        "
          class="btn btn-add "
          data-dismiss="modal"
          (click)="deleteJob()"
        >
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 *ngIf="statusJob" style="color: #868579;"  class="modal-title" id="exampleModalLabel">
          Confirmation de masquage 
        </h2>
        <h2 *ngIf="!statusJob" style="color: #868579;"  class="modal-title" id="exampleModalLabel">
          Confirmation de récupération
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-4">
            <img src="assets/images/1.jpg" alt="" />
          </div>
          <div class="col-8">
            <h3 *ngIf="statusJob"  style="position: relative; top: 40px !important;color: #868579;">
              Êtes-vous sûr(e) de vouloir masquer cette offre d'emploi ?
            </h3>
            <h3 *ngIf="!statusJob" style="position: relative; top: 40px !important;color: #868579;">
              Êtes-vous sûr(e) de vouloir réafficher cette offre d'emploi ?
            </h3>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          style="
            background: white!important;
            color: #62B9CB!important;
            border: 1px solid #62B9CB!important;
          "
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="getJobs()"
        >
          Annuler
        </button>
        <button
        style="
        background: #62B9CB!important;
        color: #fff!important;
      "
        class="btn btn-add "
        data-dismiss="modal"
        (click)="maskedOrDisplayJob()"
        >
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>