import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashbordComponent } from './admin/templ-admin/dashbord/dashbord.component';


import { TemplAdminComponent } from './admin/templ-admin/templ-admin.component';
import { LoginComponent } from './components/login/login.component';
import { AdminGuard } from './_guards/admin-guard.guard';
import { AuthGuard } from './_guards/auth.guard';
import { LoginGuard } from './_guards/login.guard';
import { ListComponent as ListActualiteComponent} from './admin/templ-admin/actualite/list/list.component';
import { AddComponent as AddActualiteComponent} from './admin/templ-admin/actualite/add/add.component';
import { UpdateComponent as UpdateActualiteComponent} from './admin/templ-admin/actualite/update/update.component';
import { ListJobsComponent } from './admin/templ-admin/jobs/list-jobs/list-jobs.component';
import { AddJobComponent } from './admin/templ-admin/jobs/add-job/add-job.component';

const routes: Routes = [
  { path: '', redirectTo: '/connexion', pathMatch: 'full'  , canActivate :[LoginGuard] },
  { path: 'connexion', component: LoginComponent , canActivate :[LoginGuard] },
  { path: 'admin', component: TemplAdminComponent , canActivate :[AuthGuard,AdminGuard] ,
  children: [
    { path: '', component: DashbordComponent },
    { path: 'actualites', component: ListActualiteComponent },
    { path: 'actualites/ajout', component: AddActualiteComponent },
    { path: 'actualites/modification/:id', component: UpdateActualiteComponent },
    { path: 'emplois', component: ListJobsComponent },
    { path: 'emplois/ajout', component: AddJobComponent },
  ]
  },
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
