import { HttpInterceptor ,HttpErrorResponse } from '@angular/common/http';
import { Injectable,Injector } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector :Injector,private router :Router,private authenticationService :AuthenticationService) { }

  intercept(req ,next ) {
  let authenticationService = this.injector.get(AuthenticationService)
  let tokenzedReq =req.clone({
    setHeaders: {
      Authorization : `Bearer ${authenticationService.getToken()}`
    }
  })
  return next.handle(tokenzedReq).pipe(
    catchError((error: HttpErrorResponse) => {
       let errorMsg = '';
       if (error.error instanceof ErrorEvent) {
          //console.log('This is client side error');
          errorMsg = `Error: ${error.error.message}`;
       } else {
          //console.log('This is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          this.authenticationService.logout()
          //window.location.reload();
        }

       return throwError(error.error);
    })
)
  }
 
}
 
